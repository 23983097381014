<template>
    <div class="col-12">
        <template v-if="ecommpayDataError || notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
            <p v-if="ecommpayDataError" class="alert alert-danger mt-5 text-center">{{ecommpayDataError}} {{$t('request-unsuccessful')}}</p>
        </template>
        <div v-else>
            <div class="text-center mt-5">
                <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
            </div>
        </div>
    </div>
</template>

<script>
    import {getCashtoCodeStatus} from "../../../services/backend.service";

    export default {
        // This page is accessed with a transaction ID. A call is made with that transaction ID to get the deposit status.
        // A loader is shown on the page until a redirect url is received which will be used to make the redirection.
        name: 'EcommpayDepositLoader',
        data(){
            return{
                notEnoughData: null,
                ecommpayDataError: null,
                ecommpayData: null,
                numberOfBackendCalls: 0,
            }
        },
        components:{
            Error: () => import('../../others/Error'),
        },
        mounted(){
            const transactionId = this.$router.history.current.query.transactionid;
            if(transactionId){
                this.getEcommpayDepositStatus(transactionId);
                this.intervalId = setInterval(function () {
                    this.numberOfBackendCalls = this.numberOfBackendCalls + 1;
                    this.getEcommpayDepositStatus(transactionId);
                }.bind(this), 5000)
            } else {
                this.notEnoughData = true;
            }
        },
        destroyed () {
            clearInterval(this.intervalId);
        },
        methods:{
            getEcommpayDepositStatus: function (transactionId) {
                getCashtoCodeStatus(transactionId).then(res => {
                    if(res.data.success && res.data.data && res.data.data.redirectUrl){
                        clearInterval(this.intervalId);
                        document.location.href = res.data.data.redirectUrl;
                    } else if(res.data.success === false || res.data.data.transactionStatus === 'ERROR') {
                        clearInterval(this.intervalId);
                        this.ecommpayDataError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                    } else if (this.numberOfBackendCalls === 5) {
                        clearInterval(this.intervalId);
                        this.ecommpayDataError = this.$t('error-2');
                    }
                }).catch( e => {
                    clearInterval(this.intervalId);
                    this.ecommpayDataError = this.$t('error-2');
                    console.log(`%c Error in PaymentCenter.vue - method: getWebPayData - ${e}`, 'color:red');
                });
            }
        }
    }
</script>

<style scoped></style>
